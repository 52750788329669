var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.showLoginForm && !_vm.showForgotPassword)?_c('div',[_c('p',[_c('label',[_vm._v("Email:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form-control",attrs:{"name":"email","type":"email"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}})]),(_vm.email_error)?_c('p',{staticClass:"error_msg"},[_vm._v(_vm._s(_vm.email_error))]):_vm._e(),_c('p',[_c('label',[_vm._v("Password:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control",attrs:{"name":"password","type":"password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}})]),(_vm.password_error)?_c('p',{staticClass:"error_msg"},[_vm._v(" "+_vm._s(_vm.password_error)+" "),(_vm.show_signup_link)?_c('a',{on:{"click":_vm.switch_to_signup}},[_vm._v("HERE")]):_vm._e()]):_vm._e(),_c('p'),_c('div',{staticClass:"row g-0 align-items-end"},[_c('label',[_vm._v("Email Verification Code")]),_c('div',{staticClass:"col"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.otp),expression:"otp"}],staticClass:"form-control",attrs:{"name":"otp","type":"text","disabled":!_vm.otp_sent},domProps:{"value":(_vm.otp)},on:{"input":function($event){if($event.target.composing)return;_vm.otp=$event.target.value}}})]),_c('button',{staticClass:"btn btn-sm mx-2 py-2 px-0 col-7",class:{
            'send-me-code' : _vm.countDownTimerForOTP == 0,
            'btn-gray' : _vm.countDownTimerForOTP > 0
          },attrs:{"loading":_vm.otp_sending,"disabled":_vm.otp_sending || (!_vm.email && !_vm.password) || _vm.countDownTimerForOTP > 0},on:{"click":_vm.send_email_otp}},[_vm._v(" "+_vm._s(_vm.otp_sent ? `Resend me a code ${(_vm.countDownTimerForOTP > 0 ? `(${_vm.countDownTimerForOTP})` : '')}` : 'Send me a verification code')+" ")])]),_c('p'),(_vm.otp_error)?_c('p',{staticClass:"error_msg"},[_vm._v(" "+_vm._s(_vm.otp_error)+" ")]):_vm._e(),_c('button',{staticClass:"btn btn-block mb-2",class:{
        'btn-primary' : _vm.otp_sent,
        'btn-gray' : !_vm.otp_sent
      },attrs:{"disabled":_vm.loading || !_vm.otp_sent},on:{"click":_vm.login}},[_c('span',{staticClass:"ml-3"},[_c('strong',[_vm._v(_vm._s(_vm.buttonText))])])]),_c('a',{attrs:{"href":"#"},on:{"click":_vm.showForgotPasswordForm}},[_c('p',{staticStyle:{"padding-top":"12px"}},[_vm._v("Forgot Password")])]),_c('a',{attrs:{"href":"#"},on:{"click":_vm.exit}},[_c('p',[_vm._v("Use a different sign in method")])])]):(!_vm.showLoginForm && _vm.showForgotPassword)?_c('div',[_c('ForgotPassword')],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }