import Vue from 'vue'
import Router from 'vue-router'

/* Implemented as a factory in order to prevent the router from
 * auto loading
 */
function router_factory() {

    Vue.use(Router)

    return new Router({
        routes: [
            {
                path: '/',
                name: 'push',
                component: () => import('./views/RedirectHandler.vue'),
                meta: {
                    title: "SupplierIQ"
                }
            },
            {
                path: '/dashboard/',
                name: 'home',
                component: () => import('./views/Dashboard.vue'),
                meta: {
                    title: "Dashboard"
                }
            },
            {
                path: '/org-selector/',
                name: 'orgselector',
                component: () => import('./views/OrgSelector.vue'),
                meta: {
                    title: "Choose Organisation"
                }
            },
            {
                path: '/supplier-list/:trendStatus?',
                name: 'supplierlist',
                component: () => import('./views/SupplierList.vue'),
                meta: {
                    title: "Your Suppliers"
                }
            },
            {
                path: '/portfolio-insights/',
                name: 'portfolioinsights',
                component: () => import('./views/PortfolioInsights.vue'),
                meta: {
                    title: "Portfolio Insights"
                }
            },
            {
                path: '/settings/',
                name: 'settings',
                component: () => import('./views/Settings.vue'),
                meta: {
                    title: "Account Settings"
                }
            },
            // {
            //     path: '/alert-settings',
            //     name: 'alertSettings',
            //     component: () => import('./views/AlertSettings.vue'),
            //     meta: {
            //         title: "Alert Settings"
            //     }
            // },
            {
                path: '/alert-list',
                name: 'alertlist',
                component: () => import('./views/AlertList.vue'),
                meta: {
                    title: "Alert List"
                }
            },
            {
                path: '/invite/',
                name: 'invite',
                component: () => import('./views/Invite.vue'),
                meta: {
                    title: "Share PayPredict with friends"
                }
            },
            {
                path: '/contact/',
                name: 'contact',
                component: () => import('./views/Contact.vue'),
                meta: {
                    title: "Contact Us (coming soon?)"
                }
            },
            {
                path: '/profile/:id',
                name: 'profile',
                component: () => import('./views/Profile.vue'),
                meta: {
                    title: "Supplier Profile"
                }
            },
            {
                path: '/supplier/add',
                name: 'supplier_add',
                component: () => import('./views/AddSupplier.vue'),
                meta: {
                    title: "Add Supplier in your list"
                }
            },
            {
                path: '/quickcheck',
                name: 'quickcheck',
                component: () => import('./views/QuickCheck.vue'),
                meta: {
                    title: "Bizcheck"
                }
            },
        ],
        mode: "history",
        base: "/sp/",
        scrollBehavior() {
            return { x: 0, y: 0 }
        }
    })
}

export default router_factory
